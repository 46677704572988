import React, { useState, useEffect } from "react";
import { getBiogPage } from "../contentful";
import marked from "marked";

const Biography = () => {
  const [contents, setContents] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getBiogPage().then((data) => {
      setContents(data[0].fields);
      console.log(data[0]);
    });
  }, []);

  const Slideshow = ({ items }) => {
    const [imageIdx, setImageIdx] = useState(0);

    useEffect(() => {
      setTimeout(() => {
        if (imageIdx < items.length - 1) {
          setImageIdx(imageIdx + 1);
        } else {
          setImageIdx(0);
        }
      }, 4000);
    }, [imageIdx]);

    return (
      <div className='slide-show'>
        {items && <img src={items[imageIdx]?.fields?.file.url} />}
      </div>
    );
  };

  return (
    <div className='page-contents'>
      <div className='biog-page'>
        {/* {contents.slideshow && <Slideshow items={contents.slideshow} />} */}
        {contents.mainImage && <img src={contents.mainImage.fields.file.url} />}
        {contents.text && (
          <section
            dangerouslySetInnerHTML={{ __html: marked(contents.text) }}
          />
        )}
      </div>
    </div>
  );
};

export default Biography;
