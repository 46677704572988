import React from "react";
import { Link } from "react-router-dom";
import marked from "marked";
import "./styles.scss";

const VideoCard = ({ v, i }) => {
  console.log(v);
  return (
    <div className='video-card'>
      <div className='video-card__video-container'>
        <article
          dangerouslySetInnerHTML={{ __html: marked(v.fields.embedCode) }}
        />
      </div>
      <div className='video-card__link-container'>
        {v.fields?.composition?.fields?.title && (
          <Link
            className='video-card__link'
            to={`/music/${v?.fields?.composition?.fields?.title}`}
          >
            visit composition
          </Link>
        )}
      </div>
    </div>
  );
};

export default VideoCard;
