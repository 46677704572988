import React from "react";
import "./styles.scss";
import Score from "../../assets/ingenga3.jpg";
import { Dialog } from "@material-ui/core";

const ContactModal = ({ onClose, open }) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <div className='contact-modal'>
        <img src={Score} className='contact-modal__score' />
        <h1>Contact Me</h1>
        <p>I would be happy to hear from you at:</p>
        <a href='mailto:laurenredhead@gmail.com'>laurenredhead@gmail.com</a>
        <div className='contact-modal--close-button'>
          <small onClick={onClose}>close</small>
        </div>
      </div>
    </Dialog>
  );
};

export default ContactModal;
