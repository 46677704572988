import React from "react";

const style = {
  width: "100%",
  minHeight: "55px",
  color: "white",
  background: "#bbb",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};
const Footer = (props) => {
  return (
    <div style={style}>
      <small>
        Designed and built by Adam Woolf @{" "}
        <a style={{ color: "black" }} href='https://www.webspinner.eu'>
          WebSpinner.eu
        </a>
      </small>
    </div>
  );
};

export default Footer;
