import React from "react";
import "./HeaderStyles.scss";
import Score from "../../assets/ingenga.jpg";
import { Menu } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

const Header = ({ toggleNav }) => {
  return (
    <div className='header-container'>
      <div className='header-container__content'>
        <div className='header-container__text'>
          <h1>Lauren Redhead</h1>
        </div>
        <img src={Score} />
        <div className='header-container__background' />
      </div>
      <h3>Composition • Organ Performance • Contemporary Musicology</h3>

      <div className='burger'>
        <IconButton onClick={toggleNav}>
          <Menu />
        </IconButton>
      </div>
    </div>
  );
};

export default Header;
