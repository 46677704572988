import React from "react";

const SearchBar = ({ filterItems, title, placeholder }) => {
  return (
    <div
      className='search-container'
      style={{ display: "flex", flexDirection: "column" }}
    >
      <h2 style={{ color: "white", marginBottom: 2 }}>{title}</h2>{" "}
      <div>
        <input
          className='music-search-input'
          placeholder={placeholder}
          onChange={filterItems}
        />
      </div>
    </div>
  );
};

export default SearchBar;
