import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import marked from "marked";
import { Link } from "react-router-dom";

const PostItem = ({ post, fullPost }) => {
  const containerRef = useRef(null);
  const outerRef = useRef();
  const [isVisible, setIsVisible] = useState(false);

  const toggleClass = (entries) => {
    // console.log(entries);
    const [entry] = entries;
    // console.log(entry);
    setIsVisible(entry.isIntersecting);
  };

  const options = {
    threshold: 0.2,
  };

  const date = new Date(post.date);
  return (
    <div
      ref={outerRef}
      style={fullPost && { margin: 20, height: "fit-content" }}
      className='post-item-container'
    >
      <div
        ref={containerRef}
        className={`post-item ${isVisible ? "color" : ""}`}
      >
        <div className={!fullPost ? "post-item__header" : ""}>
          <div className={!fullPost ? "post-item__header__left" : ""}>
            {post.title && <h1>{post.title}</h1>}
            {post.date && <p>{date.toDateString()}</p>}
          </div>
          {post.mainImage && (
            <div className='post-item__header__right'>
              <img src={post.mainImage.fields.file.url} />
            </div>
          )}
        </div>
        {post.body && (
          <div
            className='post-item__body'
            dangerouslySetInnerHTML={{ __html: marked(post?.body) }}
          />
        )}
        <hr />
        {post.downloadPdf && (
          <div style={{ marginTop: 40 }}>
            <a
              className='button'
              download
              href={post.downloadPdf.fields.file.url}
              target='_blank'
            >
              Download PDF
            </a>
          </div>
        )}
      </div>
      {/* {containerRef.current && containerRef.current.scrollHeight > 450 && ( */}
      {!fullPost && (
        <div className='more-button'>
          <Link className='button' to={`/post/${post.slug}`}>
            Read more <span>{">>"}</span>{" "}
          </Link>
        </div>
      )}
      {!fullPost && <div className='fade-out' />}
    </div>
  );
};

export default PostItem;
