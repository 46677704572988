import React, { useState, useEffect } from "react";
import { getPosts, getTextAndPublicationsText } from "../contentful";
import PostItem from "../components/PostItem/PostItem";
import YearHeader from "../components/YearHeader/YearHeader";
import marked from "marked";
import SearchBar from "../components/SearchBar/SearchBar";
const TextsAndPublications = () => {
  const [posts, setPosts] = useState([]);
  const [text, setText] = useState("");
  const [displayPosts, setDisplayPosts] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getPosts().then((data) => {
      setDisplayPosts(data.filter((p) => p.fields.academicPost));
      setPosts(data.filter((p) => p.fields.academicPost));
    });

    getTextAndPublicationsText().then((data) => setText(data[0].fields.text));
  }, []);

  const yearsArray = [
    ...new Set(
      posts
        .filter((p) => p.fields.academicPost)
        .map((c) => new Date(c.fields.date).getFullYear())
    ),
  ];

  const filterPosts = (e) => {
    const term = e.target.value;
    if (term === "") {
      setDisplayPosts(posts);
    } else {
      setDisplayPosts(
        posts.filter((c) => {
          return (
            c.fields.title?.toLowerCase().includes(term.toLowerCase()) ||
            c.fields.body?.toLowerCase().includes(term.toLowerCase())
          );
        })
      );
    }
  };

  return (
    <div className='page-contents'>
      {text.length > 0 && (
        <section
          className='white-panel'
          dangerouslySetInnerHTML={{ __html: marked(text) }}
        />
      )}

      <SearchBar
        title='Search posts'
        placeholder='Type to search titles and content'
        filterItems={filterPosts}
      />
      {yearsArray.map((y) => {
        const postsToShow = displayPosts.filter(
          (p) => new Date(p.fields.date).getFullYear() === y
        );
        if (postsToShow.length === 0) return null;
        return (
          <div>
            <YearHeader year={y} />
            <div className='homepage-posts-container'>
              {postsToShow.map((post) => (
                <PostItem key={post.sys.id} post={post.fields} />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TextsAndPublications;
