import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PostItem from "../components/PostItem/PostItem";
import { getSinglePost } from "../contentful";

const PostDetails = () => {
  const [post, setPost] = useState({});
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getSinglePost(id).then((data) => setPost(data[0].fields));
  }, [id]);
  return (
    <div className='page-contents'>
      {post && <PostItem fullPost post={post} />}
    </div>
  );
};

export default PostDetails;
