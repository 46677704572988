import React, { useRef, useEffect, useState } from "react";
import "./YearStyles.scss";
const YearHeader = ({ year }) => {
  const ref = useRef();
  const [test, setTest] = useState("transparent");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (ref.current && ref.current.getBoundingClientRect().y < 20) {
        setTest("rgba(0,0,0,.7)");
      } else {
        setTest("transparent");
      }
    });
  }, [ref.current]);

  return (
    <div
      className='year year-container'
      style={{ backgroundColor: test }}
      ref={ref}
    >
      <h2>{year}</h2>
    </div>
  );
};

export default YearHeader;
