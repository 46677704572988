import React from "react";
import { NavLink } from "react-router-dom";
import "./SideNavStyles.scss";

const SideNav = ({ setContactModal, openMobileNav, close }) => {
  return (
    <div className={`side-nav ${openMobileNav ? "side-nav--open" : ""}`}>
      <div className='side-nav__links-container'>
        <NavLink
          onClick={close}
          activeClassname='active'
          className='side-nav__links-container__link'
          exact
          to='/'
        >
          Home{" "}
        </NavLink>
        <NavLink
          onClick={close}
          activeClassname='active'
          className='side-nav__links-container__link'
          exact
          to='/biography'
        >
          Biography{" "}
        </NavLink>
        <NavLink
          onClick={close}
          activeClassname='active'
          className='side-nav__links-container__link'
          to='/music'
        >
          Music{" "}
        </NavLink>
        <NavLink
          onClick={close}
          activeClassname='active'
          className='side-nav__links-container__link'
          to='/media'
        >
          Media{" "}
        </NavLink>

        <NavLink
          onClick={close}
          activeClassname='active'
          className='side-nav__links-container__link'
          exact
          to='/performance'
        >
          Performances{" "}
        </NavLink>
        <NavLink
          onClick={close}
          activeClassname='active'
          className='side-nav__links-container__link'
          exact
          to='/practice_research'
        >
          Practice Research{" "}
        </NavLink>
        <NavLink
          onClick={close}
          activeClassname='active'
          className='side-nav__links-container__link'
          exact
          to='/texts_and_publications'
        >
          Text & Publications{" "}
        </NavLink>
        <span
          onClick={setContactModal}
          className='side-nav__links-container__link'
        >
          Contact
        </span>
      </div>
    </div>
  );
};

export default SideNav;
