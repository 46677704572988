const client = require("contentful").createClient({
  space: "gvg6xu1kb1xz",
  accessToken: "BfjsAShH-rRC2lbnHgQoZxjDbBWR7z1m6u7pw8DtMGU",
});
var gt = new Date().toISOString();

const getPosts = () =>
  client
    .getEntries({ content_type: "post", limit: 500, order: "-fields.date" })
    .then((response) => response.items);

const getSinglePost = (slug) =>
  client
    .getEntries({ content_type: "post", "fields.slug": slug })
    .then((response) => response.items);

const getCompositions = () =>
  client
    .getEntries({ content_type: "composition", limit: 500 })
    .then((response) => response.items);

const getSingleComposition = (slug) =>
  client
    .getEntries({ content_type: "composition", "fields.title": slug })
    .then((response) => response.items);

const getVideos = () =>
  client
    .getEntries({ content_type: "video", order: "fields.order" })
    .then((response) => response.items);

const getAudios = () =>
  client
    .getEntries({ content_type: "soundcloudLink", order: "fields.order" })
    .then((response) => response.items);

const getBiogPage = () =>
  client
    .getEntries({ content_type: "biogPage" })
    .then((response) => response.items);

const getHomePageText = () =>
  client
    .getEntries({ content_type: "homePageText" })
    .then((response) => response.items);

const getTextAndPublicationsText = () =>
  client
    .getEntries({ content_type: "textPublications" })
    .then((response) => response.items);

const getPerformancePage = () =>
  client
    .getEntries({ content_type: "performancePage" })
    .then((response) => response.items);

const getPracticeResearchPage = () =>
  client
    .getEntries({ content_type: "practiceResearchPage" })
    .then((response) => response.items);

export {
  getPracticeResearchPage,
  getTextAndPublicationsText,
  getBiogPage,
  getPosts,
  getCompositions,
  getSingleComposition,
  getVideos,
  getAudios,
  getPerformancePage,
  getHomePageText,
  getSinglePost,
};
