import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { Link } from "react-router-dom";

const CompositionListItem = ({ composition, id }) => {
  const containerRef = useRef();
  const [show, setShow] = useState(false);

  const options = {
    threshold: 0.5,
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle("show", entry.isIntersecting);
        if (entry.isIntersecting) {
          setShow(true);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    if (containerRef.current) {
      return observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef, options]);

  const hasMoreToSee = () => {
    return (
      composition.scoreImage ||
      composition.videoLinks ||
      composition.soundcloudLinks ||
      composition.longDescription ||
      composition.downloadPdf
    );
  };
  return (
    <div
      ref={containerRef}
      className='compositions-list-item'
      style={
        composition.scoreImage && show
          ? {
              backgroundImage: `linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), url(${composition.scoreImage.fields.file.url})`,
            }
          : {}
      }
    >
      <h2>{composition.title}</h2>
      <h3>{composition.settingShortDescription}</h3>

      {hasMoreToSee() && (
        <Link
          className='compositions-list-item--button'
          to={`/music/${composition.title}`}
        >
          {`>> more`}
        </Link>
      )}
    </div>
  );
};

export default CompositionListItem;
