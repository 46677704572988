import React, { useState, useEffect } from "react";
import { getCompositions } from "../contentful";
import CompositionListItem from "../components/CompositionListItem/CompositionListItem";
import SearchBar from "../components/SearchBar/SearchBar";
import YearHeader from "../components/YearHeader/YearHeader";

const Music = () => {
  const [comps, setComps] = useState([]);
  const [displayComps, setDisplayComps] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getCompositions().then((data) => {
      setComps(data);
      setDisplayComps(data);
    });
  }, []);

  const filterScores = (e) => {
    const term = e.target.value;
    if (term === "") {
      setDisplayComps(comps);
    } else {
      setDisplayComps(
        comps.filter((c) => {
          return (
            c.fields.title?.toLowerCase().includes(term.toLowerCase()) ||
            c.fields.settingShortDescription
              ?.toLowerCase()
              .includes(term.toLowerCase())
          );
        })
      );
    }
  };

  const yearsArray = [...new Set(displayComps.map((c) => +c.fields.year))];
  console.log(yearsArray);

  return (
    <div>
      <SearchBar
        title='Search compositions'
        placeholder='Type to search titles and descriptions'
        filterItems={filterScores}
      />
      {yearsArray
        .sort()
        .reverse()
        .map((year) => (
          <div>
            <YearHeader year={year} />
            {displayComps
              .filter((c) => c.fields.year === year)
              .map((c) => (
                <CompositionListItem composition={c.fields} id={c.sys.id} />
              ))}
          </div>
        ))}
    </div>
  );
};

export default Music;
