import React, { useState, useEffect } from "react";
import marked from "marked";
import { getPerformancePage, getVideos, getPosts } from "../contentful";
import VideoCard from "../components/VideoCard/VideoCard";
import PostItem from "../components/PostItem/PostItem";
import SearchBar from "../components/SearchBar/SearchBar";
import YearHeader from "../components/YearHeader/YearHeader";

const Performance = () => {
  const [text, setText] = useState("");
  const [videos, setVideos] = useState([]);
  const [posts, setPosts] = useState([]);
  const [displayPosts, setDisplayPosts] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getPerformancePage().then((data) => setText(data[0].fields.text));
    getVideos().then((data) =>
      setVideos(data.filter((v) => v.fields.performancePage))
    );
    getPosts().then((data) => {
      setPosts(data.filter((p) => p.fields.performance));
      setDisplayPosts(data.filter((p) => p.fields.performance));
    });
  }, []);

  const filterPosts = (e) => {
    const term = e.target.value;
    if (term === "") {
      setDisplayPosts(posts);
    } else {
      setDisplayPosts(
        posts.filter((c) => {
          return (
            c.fields.title?.toLowerCase().includes(term.toLowerCase()) ||
            c.fields.body?.toLowerCase().includes(term.toLowerCase())
          );
        })
      );
    }
  };

  const yearsArray = [
    ...new Set(
      posts
        .filter((p) => p.fields.performance)
        .map((c) => new Date(c.fields.date).getFullYear())
    ),
  ];

  return (
    <div className='page-contents'>
      {text.length > 0 && (
        <section
          className='white-panel'
          dangerouslySetInnerHTML={{ __html: marked(text) }}
        />
      )}
      <div className='performance-content-section'>
        {posts.length > 0 && (
          <div className='homepage-posts-container'>
            <SearchBar
              title='Search posts'
              placeholder='Type to search titles and content'
              filterItems={filterPosts}
            />

            {yearsArray.map((y) => {
              const postsToShow = displayPosts.filter(
                (p) => new Date(p.fields.date).getFullYear() === y
              );

              if (postsToShow.length === 0) return null;
              return (
                <div>
                  <YearHeader year={y} />
                  <div className='homepage-posts-container'>
                    {postsToShow.map((post) => (
                      <PostItem key={post.sys.id} post={post.fields} />
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <div className='performance-page-videos'>
          {videos.map((v, i) => (
            <VideoCard v={v} i={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Performance;
