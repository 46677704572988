import React, { useState, useEffect } from "react";
import { getVideos, getAudios } from "../contentful";
import { Link } from "react-router-dom";
import marked from "marked";
import VideoCard from "../components/VideoCard/VideoCard";

const Media = () => {
  const [videos, setVideos] = useState([]);
  const [audio, setAudio] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getVideos().then((data) => setVideos(data));
    getAudios().then((data) => {
      setAudio(data);
      console.log(data);
    });
  }, []);

  return (
    <div className='page-contents'>
      <div className='video-container'>
        {videos.map((v, i) => (
          <VideoCard v={v} i={i} />
        ))}
      </div>
      <div className='audio-container'>
        {audio.map((a) => (
          <div className='audio-container__card'>
            <article
              dangerouslySetInnerHTML={{ __html: marked(a.fields.embedCode) }}
            />
            {a.fields?.composition?.fields?.title && (
              <Link
                className='audio-container__card__link'
                to={`/music/${a.fields?.composition?.fields?.title}`}
              >
                visit composition
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Media;
