import React, { useEffect, useState } from "react";
import { getSingleComposition } from "../contentful";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import marked from "marked";

const MusicDetails = () => {
  const [piece, setPiece] = useState({});
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getSingleComposition(id).then((data) => setPiece(data[0]?.fields));
  }, [id]);

  return (
    <>
      {piece?.title && (
        <div className='page-content light-background '>
          <div className='music-details__header'>
            <h1>
              {piece?.title}{" "}
              {piece?.settingShortDescription && (
                <span className='music-details__short-description'>
                  - - {piece?.settingShortDescription}
                </span>
              )}
            </h1>
            {piece?.scoreImage && (
              <div className='music-details__score-image'>
                <img src={piece.scoreImage.fields.file.url} />
              </div>
            )}
          </div>
          <div className='music-details__download-button'>
            {piece?.downloadPdf && (
              <a
                className='button'
                download
                href={piece.downloadPdf.fields.file.url}
                target='_blank'
              >
                Download Score
              </a>
            )}
          </div>
          <div className='music-details__media-container'>
            {piece?.videoLinks && (
              <div className='music-details__video'>
                {piece?.videoLinks?.map((link) => (
                  <article
                    dangerouslySetInnerHTML={{
                      __html: marked(link?.fields?.embedCode),
                    }}
                  />
                ))}
              </div>
            )}
            {piece?.soundcloudLinks && (
              <div className='music-details__soundcloud'>
                {piece?.soundcloudLinks?.map((link) => (
                  <article
                    dangerouslySetInnerHTML={{
                      __html: marked(link?.fields?.embedCode),
                    }}
                  />
                ))}
              </div>
            )}
          </div>
          {piece?.longDescription && (
            <section
              dangerouslySetInnerHTML={{
                __html: marked(piece?.longDescription),
              }}
            />
          )}
          <div className='music-details__nav'>
            <Link className='button' to='/music'>
              To all Compositions
            </Link>
            <Link className='button' to='/media'>
              To Media Page
            </Link>
          </div>
          {/* <div
        dangerouslySetInnerHTML={{
          __html: marked(piece.soundcloudLinks[0].fields.embedCode),
        }}
      /> */}
        </div>
      )}
    </>
  );
};

export default MusicDetails;
