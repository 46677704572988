import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import SideNav from "./components/SideNav/SideNav";
import Performance from "./pages/Performance";
import Footer from "./components/Footer";
import Header from "./components/Header/Header";
import PractiseAndResearch from "./pages/PractiseAndResearch";
import Music from "./pages/Music";
import TextsAndPublications from "./pages/TextsAndPublications";
import Biography from "./pages/Biography";
import MusicDetails from "./pages/MusicDetails";
import ContactModal from "./components/ContactModal/ContactModal";
import Media from "./pages/Media";
import PostDetails from "./pages/PostDetails";

const App = () => {
  const [contactModal, setContactModal] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);

  const toggleMobileNav = () => setMobileNav(!mobileNav);
  return (
    <div style={{ width: "100%" }}>
      <Header toggleNav={toggleMobileNav} />
      <div className={`app-content`}>
        <div className={`app-content__left`}>
          <SideNav
            close={toggleMobileNav}
            openMobileNav={mobileNav}
            setContactModal={() => setContactModal(true)}
          />
        </div>{" "}
        <ContactModal
          open={contactModal}
          onClose={() => setContactModal(false)}
        />
        {/* <div className='backdrop' onClick={() => setContactModal(false)} /> */}
        <div className='app-content__right'>
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/performance' component={Performance} />
            <Route path='/practice_research' component={PractiseAndResearch} />
            <Route path='/music/:id' component={MusicDetails} />
            <Route path='/music' component={Music} />
            <Route path='/biography' component={Biography} />
            <Route path='/media' component={Media} />
            <Route path='/post/:id' component={PostDetails} />
            <Route
              path='/texts_and_publications'
              component={TextsAndPublications}
            />
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
